import { useMemo } from 'react'
import useProducts from './useProducts'


const useProductsBy = (identifier: 'sku' | 'slugs', productsIdentifiers: string[]) => {
  const { products = [], isFetching } = useProducts({
    variables: {
      input: {
        [identifier]: productsIdentifiers,
        visibility: [ 'LANDING', 'VISIBLE' ],
      },
    },
    skip: !productsIdentifiers.length,
    ssr: false,
  })

  const filteredProducts = useMemo(() => {
    if (!products?.length) {
      return []
    }

    const identifierMap = new Map(productsIdentifiers.map((id, index) => [ id, index ]))

    if (identifier === 'slugs') {
      return [ ...products ].sort((a, b) => {
        return identifierMap.get(a.id.toString()) - identifierMap.get(b.id.toString())
      })
    }

    return products
      .map((product) => ({
        ...product,
        tradingItems: {
          ...product.tradingItems,
          items: product.tradingItems?.items?.filter((item) => identifierMap.has(item.sku)),
        },
      }))
      .filter((product) => Boolean(product.tradingItems.items.length))
      .sort((a, b) => {
        return identifierMap.get(a.tradingItems.items[0].sku) - identifierMap.get(b.tradingItems.items[0].sku)
      })
  }, [ products, productsIdentifiers, identifier ])

  return { isFetching, products: filteredProducts }
}


export default useProductsBy
